import { useRef } from "react";
import emailjs from '@emailjs/browser';
import myImg from '../../../assets/photos/myimg.webp';
import myImgMobile from '../../../assets/photos/myimg_mobile.webp'
import socialGithub from '../../../assets/socialIcons/github.svg';
import socialLinkedIn from '../../../assets/socialIcons/linkedin.svg';
import socialInstagram from '../../../assets/socialIcons/instagram.svg';
import { useSection } from "../../../contexts/SectionContext"; 

const ContactContent = ({ closeModal }) => {
  const { setConnectModalOpen } = useSection();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_r0i0tuw', 'template_42o2tjl', form.current, 'zGgY2p7IOzkh8VEwU')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <div id="connect" className={`connect ${closeModal ? 'bgtransp' : ''}`}>
      <div className={`${closeModal ? 'connect__wrapper_modal' : 'connect__wrapper'}`}>
        <div className="connect__imgarea">
          <img className="connect__imgarea-img" src={myImg} alt="aboutme-img" />
        </div>
          
        <div className="connect__textarea">
          <div className="connect__textarea-wrapper">
            <div className="connect__textarea-header">
              <div>
                <img className="connect__imgarea-img-mobile-small" src={myImgMobile} alt="aboutme-img" />
              </div>

              <div>
                <div className="section__text section__text_normal">Contacts</div>
                <div className="connect__divider block"></div>
              
                <div className="connect__links">
                  <a href="https://github.com/anackis"><img src={socialGithub} alt="navIcon" className='social-icon' target="_blank" rel="noopener noreferrer"/></a>
                  <a href="https://www.linkedin.com/in/aleksandrs-anackis-9831371a8/"><img src={socialLinkedIn} alt="navIcon" className='social-icon' target="_blank" rel="noopener noreferrer"/></a>
                  <a href="https://www.instagram.com/alex_anackis_/"><img src={socialInstagram} alt="navIcon" className='social-icon' target="_blank" rel="noopener noreferrer"/></a>
                </div>
                <div className="connect__email-mob">
                  <a href="tel:+37122101016">+37122101016</a>
                  <a href="mailto:anackis@inbox.lv">anackis@inbox.lv</a>
                </div>
              </div>
            </div>
            
            <form ref={form} onSubmit={sendEmail} className="form" action="#">
              <div className="form__inputs">
                <div className="form-name form__box block block_contacts block_contacts_50 form__inputs_item">
                  <div className="focus-container">
                    <input className="form__box-textarea" autoComplete="off" required name="name" id="name" type="text" placeholder="Name"/>
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="form-email form__box block block_contacts block_contacts_50 form__inputs_item">
                  <div className="focus-container">
                    <input className="form__box-textarea" autoComplete="off" required name="email" id="email" type="email" placeholder="Email"/>
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
              </div>
              
              <div className="form-text form__box block block_contacts">
                <div className="focus-container">
                  <textarea className="form__box-textarea" name="text" id="text" placeholder="Send me a message :)"></textarea>
                  <label htmlFor="text">Your message</label>
                </div>
              </div>
              
              <div className="form__policy ">
                <button type="submit" className="button block block_contacts">Send a message</button> 
              </div>
            </form>
          </div>
        </div>
        {closeModal && (
          <button onClick={() => setConnectModalOpen(false)} className="connect__modal-close-button">
            {/* Close */}
            <div className={`connect__modal-close-button__line connect__modal-close-button__rotate-45 connect__modal-close-button__line_white`}></div>
            <div className={`connect__modal-close-button__line connect__modal-close-button__rotate-minus-45 connect__modal-close-button__line_white`}></div>
          </button>
        )}
      </div>
    </div>
  );
};

  
export default ContactContent;
