import { Section } from "../section";
import ContactContent from "../contact-content/contact-content";

const ContactSection = () => {

  return (
    <Section id="connect__section">
      <ContactContent />
      <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div>
    </Section>
  )
}

export default ContactSection
