import Slider from "react-slick";
import GithubIcon from '../../../assets/socialIcons/github.svg';
import InternetIcon from '../../../assets/socialIcons/internet-icon.svg';

const ProjectsModal = ({ project, onClose, openImgSlider  }) => {
	if (!project) return null;

  const handleImageClick = (index) => {
    console.log('Image clicked:', index);
    openImgSlider(project.imgs, index); 
  };

	const sliderSettings = {
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };

	return (
		<div className="projects-modal">
			<div className="projects-modal__container">
				<button onClick={onClose} className="projects-modal__close-button">
          <div className={`projects-modal__close-button__line projects-modal__close-button__rotate-45 projects-modal__close-button__line_white`}></div>
          <div className={`projects-modal__close-button__line projects-modal__close-button__rotate-minus-45 projects-modal__close-button__line_white`}></div>
        </button>

        <div className="projects-modal__slider-wrapper">
          <Slider {...sliderSettings}>
            {project.imgs.map((img, index) => (
              <div key={index} >
                <img src={img} alt={`Project ${index}`} onClick={() => handleImageClick(index)}/>
              </div>
            ))}
          </Slider>
        </div>
        <div className="projects-modal__bottom-wrapper">
          <h1>{project.title}</h1>
          <p>{project.description_detailed}</p>
          
          <div className="projects-modal__footer">
            <div className="projects-modal__wrapper-for-links">
              {project.link && (
                <a 
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_internet"} 
                  href={project.link}
                >
                  <img src={InternetIcon} alt="GitHub"/>
                </a>
              )}
              {project.linkGitHub && (
                <a 
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_github"} 
                  href={project.linkGitHub}
                >
                  <img src={GithubIcon} alt="GitHub"/>
                </a>
              )}
            </div>
            
            <div className="projects-modal__wrapper-for-hashtags">
              {project.hashtags.map((hashtag, index) => (
                <div className={`hashtag_${index}`} key={index}>{hashtag}</div>
              ))}
            </div>
          </div>

        </div>
		</div>
	</div>
	);
};

export default ProjectsModal
