import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImgFullScreenSlider = ({ images, onClose }) => {
  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="img-fullscreen-slider">
      <div className="img-fullscreen-slider_container">
        <button onClick={onClose} className="img-fullscreen-slider__close-button">
          <div className={`img-fullscreen-slider__close-button__line img-fullscreen-slider__close-button__rotate-45 img-fullscreen-slider__close-button__line_white`}></div>
          <div className={`img-fullscreen-slider__close-button__line img-fullscreen-slider__close-button__rotate-minus-45 img-fullscreen-slider__close-button__line_white`}></div>
        </button>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index}>
              <img src={img} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImgFullScreenSlider;
