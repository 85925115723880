import { useState, useEffect } from "react";
import { useSection } from "../../../contexts/SectionContext";

const CV = () => {
  const { cvModalOpen, setCvModalOpen } = useSection();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout;
    if (cvModalOpen) {
      setVisible(true);
    } else {
      timeout = setTimeout(() => setVisible(false), 500);
    }
    return () => clearTimeout(timeout);
  }, [cvModalOpen]);

  const handleClose = () => {
    setCvModalOpen(false);
  };

  return (
    <div className={`cv ${visible ? "cv_visible" : ""}`}>
      <div className="cv-container">
        <button onClick={handleClose} className="cv__modal-close-button">
          <div className={`cv__modal-close-button__line cv__modal-close-button__rotate-45 cv__modal-close-button__line_white`}></div>
          <div className={`cv__modal-close-button__line cv__modal-close-button__rotate-minus-45 cv__modal-close-button__line_white`}></div>
        </button>
        <iframe
          src="/cv.pdf"
          title="Curriculum Vitae"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        ></iframe>
      </div>     
    </div>
  );
};

export default CV;
