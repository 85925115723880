import { createContext, useState, useContext, useEffect } from 'react';

const SectionContext = createContext({
  section: 0,
  setSection: () => {},
  menuOpened: false,
  setMenuOpened: () => {},
  screenWidth: window.innerWidth,
  isConnectModalOpen: false,
  setConnectModalOpen: () => {},
  cvModalOpen: false,
  setCvModalOpen: () => {},
  projectModalOpen: false, 
  setProjectModalOpen: () => {}, 
  selectedProject: null, 
  setSelectedProject: () => {},
  selectedImageIndex: 0,
  setSelectedImageIndex: () => {},
  imagesForSlider: [],
  setImagesForSlider: () => {} 
});
export const useSection = () => useContext(SectionContext);

export const SectionProvider = ({ children }) => {
  const [section, setSection] = useState(0);
  const [menuOpened, setMenuOpened] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isConnectModalOpen, setConnectModalOpen] = useState(false);
  const [cvModalOpen, setCvModalOpen] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesForSlider, setImagesForSlider] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate style var for displaying 80 of mobile, or 70 if the screen width is less than 650px. For blocks.
  useEffect(() => {
    function updateHeight() {
      const vh = window.innerHeight * 0.01;
      const vw = window.innerWidth;
      if (vw < 650) {
        document.documentElement.style.setProperty('--section-block-vh', `${vh * 70}px`); 
      } else {
        document.documentElement.style.setProperty('--section-block-vh', `${vh * 80}px`); 
      }
    }

    window.addEventListener('resize', updateHeight);
    updateHeight(); 

    return () => {
      window.removeEventListener('resize', updateHeight); 
    };
  }, []);


  // Calculate style var for displaying 100 of mobile. For the app height.
  useEffect(() => {
    function updateHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const value = {
    section, setSection,
    menuOpened, setMenuOpened,
    screenWidth, 
    isConnectModalOpen, setConnectModalOpen,
    cvModalOpen, setCvModalOpen,
    projectModalOpen, setProjectModalOpen,
    selectedProject, setSelectedProject,
    fullscreenOpen, setFullscreenOpen,
    selectedImageIndex, setSelectedImageIndex,
    imagesForSlider, setImagesForSlider,
  };

  return (
    <SectionContext.Provider value={value}>
      {children}
    </SectionContext.Provider>
  );
};
