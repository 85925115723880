import { useRef, useEffect, useState } from "react";
import { useScroll } from "@react-three/drei";
import { gsap } from "gsap";
import { useSection } from "../contexts/SectionContext";

export const ScrollManager = () => {
  const { section, setSection } = useSection();
  const data = useScroll();
  const isAnimating = useRef(false);
  const touchStartY = useRef(0);
  const [scrollCooldown, setScrollCooldown] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  if (data.fill) {
    data.fill.classList.add("scroll-fix");
  }

  const isInsideScrollable = (target) => {
    return target.closest('.scrollable-text') !== null;
  };

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateViewportHeight);
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  useEffect(() => {
    data.el.style.overflow = isAnimating.current ? "hidden" : "auto";

    gsap.to(data.el, {
      duration: 1,
      scrollTop: section * viewportHeight,
      onStart: () => {
        isAnimating.current = true;
        data.el.style.overflow = "hidden";
      },
      onComplete: () => {
        isAnimating.current = false;
        data.el.style.overflow = "auto";
      }
    });
  }, [section, data.el, data, viewportHeight]);

  useEffect(() => {
    const handleWheelScroll = (e) => {
      const target = e.target.closest('.scrollable-text');
      if (target) {
          // Only allow default behavior if the content is actually scrollable
          if (target.scrollHeight > target.clientHeight) {
              return; // Allow normal scroll behavior inside the scrollable element
          }
      }

      // if (isInsideScrollable(e.target)) {
      //   return; 
      // }

      e.preventDefault();

      if (!isAnimating.current && !scrollCooldown) {
        if (e.deltaY < 0 && section > 0) {
          setScrollCooldown(true);
          setSection(prev => Math.max(0, prev - 1));
        } else if (e.deltaY > 0 && section < data.pages - 1) {
          setScrollCooldown(true);
          setSection(prev => Math.min(data.pages - 1, prev + 1));
        }
        setTimeout(() => {
          setScrollCooldown(false);
        }, 2100);
      }
    };

    const handleTouchStart = (e) => {
      if ( !isInsideScrollable(e.target)) {
        touchStartY.current = e.touches[0].clientY;
      }
    };

    const handleTouchEnd = (e) => {
      if (!isInsideScrollable(e.target)) {
        const touchEndY = e.changedTouches[0].clientY;
        const touchDifference = touchStartY.current - touchEndY;

        if (!isAnimating.current && !scrollCooldown && Math.abs(touchDifference) > 1) {
          if (touchDifference > 0 && section < data.pages - 1) {
            setScrollCooldown(true);
            setSection(prev => Math.min(data.pages - 1, prev + 1));
          } else if (touchDifference < 0 && section > 0) {
            setScrollCooldown(true);
            setSection(prev => Math.max(0, prev - 1));
          }
          setTimeout(() => {
            setScrollCooldown(false);
          }, 2000);
        }
      }
    };

    data.el.addEventListener('wheel', handleWheelScroll);
    data.el.addEventListener('touchstart', handleTouchStart);
    data.el.addEventListener('touchend', handleTouchEnd);

    return () => {
      data.el.removeEventListener('wheel', handleWheelScroll);
      data.el.removeEventListener('touchstart', handleTouchStart);
      data.el.removeEventListener('touchend', handleTouchEnd);
    };
  }, [section, data.el, data.pages, setSection, isAnimating, scrollCooldown]);

  return null;
};
