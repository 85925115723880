import { useState, useEffect, memo  } from 'react';
import { Section } from '../section';
import { projects1, projects2 } from '../../../utils/projectsData';
import { Tilt } from 'react-tilt';
import GithubIcon from '../../../assets/socialIcons/github.svg';
import InternetIcon from '../../../assets/socialIcons/internet-icon.svg';
import Slider from "react-slick";

const defaultOptions = {
  reverse:        false,  // reverse the tilt direction
  max:            30,     // max tilt rotation (degrees)
  perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
  scale:          1,    // 2 = 200%, 1.5 = 150%, etc..
  speed:          1000,   // Speed of the enter/exit transition
  transition:     true,   // Set a transition on enter/exit.
  axis:           null,   // What axis should be disabled. Can be X or Y.
  reset:          true,    // If the tilt effect has to be reset on exit.
  easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

const ProjectsSection = ({ openProjectModal}) => {
  console.log('Rendering ProjectsSection');
  return (
    <Section id="projects__section">
      <div className="projects">
        <TabComponent openProjectModal={openProjectModal}/>
      </div>
    </Section>
  )
}

const TabComponent = ({ openProjectModal }) => {
  const [activeTab, setActiveTab] = useState('Tab1');
  // const [loading, setLoading] = useState(true);
  const [transitionStage, setTransitionStage] = useState('fadeIn');
  const [isCarouselActive, setIsCarouselActive] = useState(false);

  const sliderSettings = {
    // centerMode: true,
    centerPadding: '0px',
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        }
      },
    ],
  };
  
  useEffect(() => {
    const mediaQuery1199 = window.matchMedia("(max-width: 1399px)");
    const mediaQuery991 = window.matchMedia("(max-width: 991px)");
    const handleMediaQueryChange = () => {
      setIsCarouselActive(mediaQuery1199.matches);
    };

    handleMediaQueryChange();
    mediaQuery1199.addEventListener('change', handleMediaQueryChange);
    mediaQuery991.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery1199.removeEventListener('change', handleMediaQueryChange);
      mediaQuery991.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  // useEffect(() => {
  //   if (section ===  2 ) {
  //     setLoading(true);
  //   } else if (section === 3) {
  //     const timer = setTimeout(() => {
  //       setLoading(false);
  //     }, 1000); 
  //     return () => clearTimeout(timer);
  //   }
  // }, [section]);


  const handleTabChange = (newTab) => {
    setTransitionStage('fadeOut');
    setTimeout(() => {
      setActiveTab(newTab);
      setTransitionStage('fadeIn');
    }, 500);
  };

  const projects = activeTab === 'Tab1' ? projects1 : projects2;

  const renderProjects = () => {
    if (isCarouselActive) {
      // Carousel logic
      return (
        <>
          <div className={`tab-content ${transitionStage}`}>
            <Slider {...sliderSettings}>
              {projects.map((project, index) => (
                <div key={index}>
                  <ProjectCard
                    project={project}
                    activeTab={activeTab}
                    // section={section}

                    key={index}
                    openProjectModal={openProjectModal}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
      );
    } else {
      // Full grid display logic for big screens
      return (
        <div className={`tab-content ${transitionStage}`}>
          <div className={`projects__wrapper `}>
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                activeTab={activeTab}
                // section={section}
                openProjectModal={openProjectModal}
              />
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='projects__tab-component'>
      <div className="projects__button-wrapper">
        <div className=" projects__button-wrapper_block ">
          <div className="  bubble_wrap projects__title"><span className='fz-header section__text section__text_normal'>Projects</span></div>
        </div>
        <div className='projects__button-wrapper_block'>
          <button
            className={activeTab === 'Tab1' ? "projects__tab-button_active1 projects__tab-button projects__tab-button1 block block_projects" : "projects__tab-button projects__tab-button1 block block_projects"}
            onClick={() => handleTabChange('Tab1')}
          >
            With Links
          </button>
          <button
            className={activeTab === 'Tab2' ? "projects__tab-button_active2 projects__tab-button projects__tab-button2 block block_projects" : "projects__tab-button projects__tab-button2 block block_projects"}
            onClick={() => handleTabChange('Tab2')}
          >
            Only GitHub
          </button>
        </div>
        <div className='projects__button-wrapper_block'></div>
      </div>

      {renderProjects()}
    </div>
  );
};


const ProjectCard = memo(({ project, activeTab, openProjectModal }) => {
  console.log('Rendering ProjectCard', project.title);
  // const [isVisible, setIsVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTiltActive, setIsTiltActive] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1399) {
        setIsTiltActive(false); 
      } else {
        setIsTiltActive(true); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize); 

    return () => window.removeEventListener('resize', handleResize); 
  }, []);

  // useEffect(() => {
  //   if (section === undefined) {
  //     return;
  //   }

  //   let timer;
  //   if (section === 3) {
  //     timer = setTimeout(() => {
  //       setIsVisible(true);
  //     }, 1700);
  //   }

  //   return () => {
  //     if (timer) {
  //       clearTimeout(timer); 
  //     }
  //   };
  // }, [section]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % project.imgs.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + project.imgs.length) % project.imgs.length);
  };

  return (
    <>
      {isTiltActive ? (
        <div options={defaultOptions} className={activeTab === 'Tab1' ? "projects__card projects__card_pink" : "projects__card projects__card_blue"}>
          <div className={`projects__card-img-holder `}>
            <img src={project.imgs[currentImageIndex]} alt={project.title} className='projects__card-img'/>

            <div className="projects__small-arrow-wrapper">
              <button className="small-arrow left" onClick={prevImage}>
                <div className={activeTab === 'Tab1' ? "text_pink small-arrow-icon" : "text_blue small-arrow-icon"}>&lt;</div>
              </button>

              <button className='projects__zoom' onClick={() => openProjectModal(project)}>
                <svg className={activeTab === 'Tab1' ? "filter_pink" : "filter_blue"} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"> 
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314M11 8v6m-3-3h6"/>
                </svg>
              </button>
              
              <button className="small-arrow right" onClick={nextImage}>
                <div className={activeTab === 'Tab1' ? "text_pink small-arrow-icon" : "text_blue small-arrow-icon"}>&gt;</div>
              </button>
            </div>
          
            <div className="projects__wrapper-for-links">
              {project.link && (
                <a 
                  className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_internet" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_internet"} 
                  href={project.link}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img src={InternetIcon} alt="GitHub"/>
                </a>
              )}

              <a 
                className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_github" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_github"} 
                href={project.linkGitHub}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={GithubIcon} alt="GitHub"/>
              </a>
            </div>
          </div>

          <div className={``}>
            <h3 className={activeTab === 'Tab1' ? "projects__card__header1" : "projects__card__header2"}>{project.title}</h3>
            <p className='fz-text'>{project.description}</p>
            <span>
              {project.hashtags.map((hashtag, index) => (
                <div className={`hashtag_${index}`} key={index}>{hashtag}</div>
              ))}
            </span>
          </div>

        </div>
      ) : (
        <Tilt options={defaultOptions} className={activeTab === 'Tab1' ? "projects__card projects__card_pink" : "projects__card projects__card_blue"}>
          <div className={`projects__card-img-holder `}>
            <img src={project.imgs[currentImageIndex]} alt={project.title} className='projects__card-img'/>

            <div className="projects__small-arrow-wrapper">
              <button className="small-arrow left" onClick={prevImage}>
                <div className={activeTab === 'Tab1' ? "text_pink small-arrow-icon" : "text_blue small-arrow-icon"}>&lt;</div>
              </button>

              <button className='projects__zoom' onClick={() => openProjectModal(project)}>
                <svg className={activeTab === 'Tab1' ? "filter_pink" : "filter_blue"} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"> 
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314M11 8v6m-3-3h6"/>
                </svg>
              </button>
              
              <button className="small-arrow right" onClick={nextImage}>
                <div className={activeTab === 'Tab1' ? "text_pink small-arrow-icon" : "text_blue small-arrow-icon"}>&gt;</div>
              </button>
            </div>
          
            <div className="projects__wrapper-for-links">
              {project.link && (
                <a 
                  className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_internet" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_internet"} 
                  href={project.link}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img src={InternetIcon} alt="GitHub"/>
                </a>
              )}

              <a 
                className={ activeTab === 'Tab1' ? "projects__link-wrapper projects__link-wrapper1 projects__link-wrapper_github" : "projects__link-wrapper projects__link-wrapper2 projects__link-wrapper_github"} 
                href={project.linkGitHub}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={GithubIcon} alt="GitHub"/>
              </a>
            </div>
          </div>

          <div className={``}>
            <h3 className={activeTab === 'Tab1' ? "projects__card__header1" : "projects__card__header2"}>{project.title}</h3>
            <p className='fz-text'>{project.description}</p>
            <span>
              {project.hashtags.map((hashtag, index) => (
                <div className={`hashtag_${index}`} key={index}>{hashtag}</div>
              ))}
            </span>
          </div>

        </Tilt>
      )}
    </>
   
  );
});

export default ProjectsSection
